import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import { vMaska } from 'maska';
import * as VueGoogleMaps from 'vue3-google-map';
import VueSweetalert2 from 'vue-sweetalert2';
import Vue3ApexCharts from 'vue3-apexcharts';
import router from './router/index';
import store from '@/state/store';
import vco from 'v-click-outside';
import BootstrapVueNext from 'bootstrap-vue-next';
import vueChartist from 'vue-chartist';
import DropZone from 'dropzone-vue';

import 'vue-multiselect/dist/vue-multiselect.css';
import 'dropzone-vue/dist/dropzone-vue.common.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import '@/design/app.scss';
import '@vueform/multiselect/themes/default.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import api from './service/api';

// API Interceptor for handling responses
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login'; 
    }
    return Promise.reject(error);
  }
);

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(DropZone);
app.use(router);
app.use(store);
app.use(BootstrapVueNext);
app.directive('maska', vMaska);
app.use(VueSweetalert2);
app.use(vueChartist);
app.use(vco);
app.component('v-select', vSelect);
app.use(Vue3ApexCharts);
app.use(VueGoogleMaps, {
  load: {
    apiKey: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true,
});

app.mount('#app');
