import axios from "axios";
import { readFromLocalStorage } from "../state/helpers";

const api = axios.create({
baseURL: "https://mwapi.webcoompay.com/api",
//baseURL: "http://localhost:8000/api"

});

api.interceptors.request.use(
    function (config) {
        const token = readFromLocalStorage("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers['Content-Type'] = 'multipart/form-data';
            config.headers['Accept'] = 'application/json';
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default api;
