import { createStore } from 'vuex';

import modules from './modules'

const store = createStore({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
   actions: {

    emitDeviseCreatedEvent({ commit }) {
      commit('DEVISE_CREATED_EVENT')
    }
  },
  mutations: {
    DEVISE_CREATED_EVENT(state, newValue) {
      state.someStateProperty = newValue
    }
  }
})

export default store;

