import { register, login,logout } from "../../service/auth.js";

export const state = {
  currentUser: localStorage.getItem("user"),
  currentUserToken : localStorage.getItem("token"),
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
    
  },
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },
  userToken(state) {
    return JSON.parse(state.currentUserToken);
  }
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
 

  // Logs in the current user.
  async logIn({ commit, dispatch, getters }, payload) {
    try {
      const { data } = await login({ ...payload });

      // Vérifiez si la réponse indique que l'authentification a réussi
      if (data && data.success && data.data) {
        console.log("Authentication successful:", data.message);

        // Vérifiez si les données utilisateur et le token sont inclus dans la réponse
        if (data.data.user && data.data.token) {
          console.log("User:", data.data.token);
          // Enregistrez l'utilisateur et le token dans le stockage local
          localStorage.setItem('user', JSON.stringify(data.data.user));
          localStorage.setItem('token', JSON.stringify(data.data.token));

          // Mettez à jour l'état de l'utilisateur dans le store Vuex
          commit("SET_CURRENT_USER", data.data.user);
        } else {
          console.error("Unexpected response format: missing user or token");
          // Gérez les cas où les données utilisateur ou le token ne sont pas inclus dans la réponse
        }
      } else {
        console.error("Authentication failed:", data.message);
        // Gérez les cas où l'authentification a échoué en affichant un message d'erreur
      }
    } catch (error) {
      console.error("An error occurred while logging in:", error);
      // Gérez les erreurs survenues lors de la requête vers l'API
      // Vous pouvez afficher un message d'erreur ou effectuer d'autres actions en conséquence
    }
  }
,

  // Logs out the current user.
  logOut({ commit,getters }) {

    const { data } = logout(getters.userToken)
    // eslint-disable-next-line no-unused-vars
    commit("SET_CURRENT_USER", null);

  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  register({ commit, dispatch, getters }, { fullname, email, password } = {}) {
    if (getters.loggedIn) return dispatch("validate");
    const { data } = register({
      name: fullname,
      email: email,
      password: password,
      userName: "test",
    });
    commit("SET_CURRENT_USER", data.user);
    console.log(data.user);

    // return getFirebaseBackend().registerUser(email, password).then((response) => {
    //   const user = response
    //   commit('SET_CURRENT_USER', user)
    //   return user
    // });
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch("validate");
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null);
  },
};

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}
