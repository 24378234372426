import api from './api.js';
export const register = (value) => api.post('/register', value)


export async function loginUser(userData) {
  try {
    const result = await api.post('/login_admin', userData);
    console.log('resultat connexion', result);
    localStorage.setItem('token', result.data.data.token);
    console.log('Token:', result.data.data.token);

    if (result.data.data.permissions) {
      localStorage.setItem('userPermissions', JSON.stringify(result.data.data.permissions));
      console.log('Permissions stockées:', result.data.data.permissions);
    }

    // Rediriger l'utilisateur après une connexion réussie
    window.location.href = '/';
    return null;
  } catch (error) {
    console.log(error);

    let errorMessage = "";
    if (error.response && error.response.status === 500) {
      errorMessage = "Une erreur s'est produite, veuillez réessayer";
    } else {
      errorMessage = error.response;
    }

    return errorMessage;
  }
}


export async function logoutUser() {
  try {
        await api.post('/logout_admin', );
      localStorage.removeItem('token');
      window.location.href = '/login';
    return null;
  } catch (error) {  
      return "Une erreur s'est produite, veuillez réessayer"
    }
}


