<script>
import appConfig from "@/app.config";
import { layoutComputed } from '@/state/helpers.js';
import { ref, provide, onMounted } from 'vue';

export default {
  name: "app",
  page: {
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  computed: {
    ...layoutComputed
  },
  setup() {
    // Initializing refs for module state
    const initializeModuleState = () => ({
      active: ref(false),
      count: ref(0),
      features: ref([])
    });

    const modules = {
      parametre: initializeModuleState(),
      dashbord: initializeModuleState(),
      transaction: initializeModuleState(),
      droitAcces: initializeModuleState(),
      devise: initializeModuleState(),
      pays: initializeModuleState(),
      requeteReclamation: initializeModuleState(),
      operateur: initializeModuleState(),
      meansofpaiement: initializeModuleState(),
      role: initializeModuleState(),
      user: initializeModuleState()
    };

    // Fetch permissions from localStorage
    const permissions = JSON.parse(localStorage.getItem('userPermissions')) || [];
    console.log("mes permissions",permissions);
    const setModuleData = (moduleName, module) => {
      const moduleData = permissions.filter(element => element.module.libelle === moduleName);
      console.log("les modules...", moduleData);
      moduleData.forEach(element => {
        const data = element.feature;
        data.is_active = element.is_active;
        module.features.value.push(data);
        if (element.is_active) {
          module.count.value++;
        }
      });
      module.active.value = module.count.value > 0;
    };

    console.log(" module oper",modules.dashbord)
    // Setting up each module with its data
    setModuleData('Tableau de bord', modules.dashbord);
    setModuleData('Opérateur mobile', modules.operateur);
    setModuleData('Devise', modules.devise);
    setModuleData('Transactions', modules.transaction);
    setModuleData('Parametre', modules.parametre);
    setModuleData('Role', modules.droitAcces);
    setModuleData('Utilisateur', modules.user);
    setModuleData('Moyens paiement', modules.meansofpaiement);
    setModuleData('Requete_réclamation', modules.requeteReclamation);
    setModuleData('Pays', modules.pays);

    // Providing the state of each module
    provide('feature_dashbord', modules.dashbord.features.value);
    console.log("side dashbord feature", modules.dashbord.features.value);
    provide('feature_operateur', modules.operateur.features.value);
    provide('feature_devise', modules.devise.features.value);
    provide('feature_transaction', modules.transaction.features.value);
    provide('feature_parametre', modules.parametre.features.value);
    provide('feature_droitAcces', modules.droitAcces.features.value);
    provide('feature_user', modules.user.features.value);
    provide('feature_meansofpaiement', modules.meansofpaiement.features.value);
    provide('feature_requeteReclamation', modules.requeteReclamation.features.value);
    provide('feature_pays', modules.pays.features.value);
    provide('feature_role', modules.role.features.value);

    //Active fonctionnalites

     // Providing the state of each module
    provide('active_dashbord', modules.dashbord.active);
    console.log("active_dashbord", modules.dashbord.active);
    provide('active_operateur', modules.operateur.active.value);
    provide('active_devise', modules.devise.active.value);
    provide('active_transaction', modules.transaction.active.value);
    provide('active_parametre', modules.parametre.active.value);
    provide('active_droitAcces', modules.droitAcces.active.value);
    provide('active_user', modules.user.active.value);
    provide('active_meansofpaiement', modules.meansofpaiement.active.value);
    provide('active_requeteReclamation', modules.requeteReclamation.active.value);
    provide('active_pays', modules.pays.active.value);
    provide('active_role', modules.role.active.value);

    // Logging the features for the role module
    console.log("Fonctionnalités du dashbord:", modules.dashbord.features.value);

    onMounted(() => {
      initSiteLayout();
    });

    // Layout initialization method
    const initSiteLayout = () => {
      document.body.setAttribute('data-layout', modules.layoutType);
      document.body.setAttribute('data-sidebar-size', modules.layoutWidth);
      document.body.setAttribute('data-bs-theme', modules.mode);
      if (modules.dir === 'rtl') {
        document.body.setAttribute('dir', modules.dir);
      }
    };

    return {
      ...modules
    };
  }
};

</script>

<style>
.multiselect__tags-wrap .multiselect__tag {
  background-color: #cc3446 !important;
}
.multiselect__option--highlight {
  background: #cc3446 !important;
  outline: none;
  color: white;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #cc3446 !important;
  color: #fff;
}
</style>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
