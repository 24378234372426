import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
    mode: (state) => state.mode,
    dir: (state) => state.dir
  }),
}

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword'])

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export function saveToLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

export function readFromLocalStorage(key) {
  return localStorage.getItem(key);
}

export function removeFromLocalStorage(key) {
  localStorage.removeItem(key);
}

export function formatDate(dateString) {
  let date = new Date(dateString);

  // Tableau des noms des mois en français
  const frenchMonths = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ];

  // Extraire les éléments de date
  let day = date.getDate();
  let month = frenchMonths[date.getMonth()]; // Utiliser le tableau des mois en français
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Ajouter un zéro devant les chiffres uniques
  day = (day < 10 ? '0' : '') + day;
  hours = (hours < 10 ? '0' : '') + hours;
  minutes = (minutes < 10 ? '0' : '') + minutes;

  // Retourner la date formatée
  return `${day} ${month} ${year}, ${hours}:${minutes}`;
}

